<template>
  <div class="message-list-wrapper">
    <div v-if="!messages.length && friendsList.length">
      <ul class="notification-list">
          <div class="container empty-messages-info text-center mt-5"><b>Nie masz konwersacji</b></div>
          <div class="quote messages-quote">
              To, że milczę, <br>nie znaczy, że nie mam <br>nic do powiedzenia
          </div>
            <div class="container mt-4">
              <hr class="section-spacer" />
            </div>
          <div class="container empty-messages-info text-center py-2"><b>Propozycje</b></div>
          <li class="notification profile-notification">
                <div class="mb-2 pb-4">
                  <div class="friends-list-wrapper mt-2 mb-2" v-for="(item, key) in friendsList" :key="key">
                      <div class="col-4">
                      <router-link :to="{name: 'OtherUserProfile', params: {id: item.id}}">
                        <div class="notification-avatar">
                          <img :src="item.thumb_url ? plainUrl + item.thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="item.username" />
                        </div>
                      </router-link>
                      </div>
                      <div class="col-4">
                        <div class="notification-content-header row">
                          <div class="info-title">
                            <span>{{item.username}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <router-link  :to="{name: 'Messages', params: {id: item.id}}" class="w-100">
                          <button class="btn-black-border w-100 text-center mb-1">Napisz</button>
                        </router-link>
                      </div>
                    </div>
                </div>
            </li>
      </ul>
    </div>
    <div v-else-if="!messages.length">
      <ul class="notification-list">
          <div class="container empty-messages-info text-center">
              <b>Nie masz konwersacji</b>
          </div>
          <div class="container empty-container pb-4" >
            <div class="quote messages-quote">
              To, że milczę, <br>nie znaczy, że nie mam <br>nic do powiedzenia
            </div>
            <div>
              <div class="text-center mb-3">
                  Nawiąż swoje pierwsze <br>czytelnicze relacje. <br>Przejdź do wyszukiwania osób
              </div>
              <router-link to="/wyszukiwanie"><button class="btn-black-full w-100 mt-2">Przeglądaj Czytelników Bookme</button></router-link>
            </div>
          </div>
      </ul>
    </div>
    <ul class="notification-list" v-else-if="messages.length">
    <swipe-list
      ref="list"
      :items="messages"
      item-key="id"
      @swipeout:click="close"
    >
      <template v-slot="{ item }">
        <li class="notification profile-notification">
            <div class="slide-option no-border" >
            <div class="slide-option-slide">
              <div class="slide-option-content">
                <div class="container">
                  <router-link  :to="{name: 'Messages', params: {id: item.users[1].id}}">
                  <div class="message-list-wraper">
                      <div class="">
                        <div class="notification-avatar mr-3">
                          <img :src="item.users[1].thumb_url ? plainUrl + item.users[1].thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="item.users[1].username" />
                        </div>
                      </div>
                      <div class="notification-content">
                        <div class="notification-content-header row">
                          <div class="col-7 info-title">
                            <a href="#">{{item.users[1].username}}</a>
                          </div>
                        </div>
                        <div class="notification-content-content" v-if="item.messages">
                          {{item.messages.content.slice(0,10)}}...<br/>
                          <span class="notification-content-date">
                            <span v-if="formatted_date == item.messages.created_date.slice(0, 10)">{{item.messages.created_date.slice(11,16)}}</span>
                            <span v-else>{{item.messages.created_date.slice(0,10)}}</span>
                          </span>
                        </div>
                      </div>
<!--                      <div @click.prevent class="info-options ml-3">-->
<!--                        <a class="fas fa-ellipsis-h"></a>-->
<!--                      </div>-->
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </template>
      <template v-slot:right="{ item }">
        <div @click="remove(item)" class="swipeout-action option-red text-center">
            <a href="#">
              <i class="far fa-trash-alt"></i>
              <span class="d-block mt-1">
                Usuń
              </span>
            </a>
        </div>
      </template>
      <template v-slot:empty>
        <div>
          Brak wiadomości
        </div>
      </template>
    </swipe-list>
      </ul>
      <div v-else>
        <div><Loader /></div>
      </div>
  </div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions'
import Loader from '../components/Loader'
export default {
  name: 'MessageList',
  components: {
    SwipeList,
    Loader
  },
  data () {
    return {
      isOpen: false,
      avatar: 'avatar-image-big.jpg',
      friendsList: [],
      messages: [],
      messagesList: [],
      proposedList: [],
      today: '',
      formatted_date: ''
    }
  },
  async created () {
    await this.getAllMessages()
    await this.$https('/friends', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(res => {
        this.friendsList = res.data.response
      })
      .catch(error => {
        console.log('', error)
      })

    await this.$https('/proposed', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(res => {
        for (let i = 0; i <= res.data.response[1].items.length / 5; i++) {
          this.proposedList.push(res.data.response[1].items[i])
        }
      })
      .catch(error => {
        console.log('', error)
      })

    this.today = new Date()
    this.formatted_date = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate()
  },
  methods: {
    getAllMessages () {
      const getData = 'action=getall'
      this.$https('/messages', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        },
        data: getData
      })
        .then(res => {
          this.messages = res.data.response
        })
        .catch(error => {
          console.log('', error)
        })
    },
    close (e) {
      this.$refs.list.closeActions(e.id)
    },
    remove (item) {
      this.messages = this.messages.filter(i => i !== item)
      const userId = item.users[1].id
      const getData = `action=delete&user=${userId}`
      this.$https('/messages', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        },
        data: getData
      })
        .then(res => {
          this.messages = res.data.response
          this.getAllMessages()
        })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.empty-messages-info {
  padding-top: 30px;
}
  .message-list-wrapper {
    margin-top: 40px;
    margin-bottom: 0;

    &-empty {
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .friends-list-wrapper {
    display: flex;
    align-items: center;
  }

  .contact-wrapper {
    margin-top: 70px;;
  }

  .add-friends-icon {
      width: 110px;
      height: 110px;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

    .fa-user-plus {
      padding: 10px;
      font-size: 80px;
    }
  }
  .msg-friend-wrapper {
    display: flex;
    justify-content: center;
  }
  .notification-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50px;
      margin: 0 auto;
  }
  .notification-content {
    height: 75px;
    width: 300px;
  }
  .notification-content-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .message-list-wraper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info-options {
    color: #000;
  }

  .notification-list .notification {
    border-bottom: 2px solid #fff;
  }

  .app-content-box{
    min-height: 100vh;
  }
  .notification-list .notification[data-v-d817c18c] {
    border-bottom: none;
  }
  .info-title {
    word-break: break-word;
  }
</style>
