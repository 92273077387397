<template>
  <div>
    <TabsNotification :activeTab="activeTab" :tabs="tabs" >
      <template slot="tab-head-message">
        <div  class="wrapper">konwersacje <div v-if="chatsAmount > 0" class="notification-amount">{{chatsAmount}}</div></div>
      </template>
      <template slot="tab-panel-message">
        <MessagesList />
      </template>

      <template slot="tab-head-invites">
        zaproszenia
      </template>
      <template slot="tab-panel-invites">
        <InvitesList />
      </template>

        <template slot="tab-head-notification">
        <div  class="wrapper">powiadomienia <div v-if="remindersAmounts > 0" class="notification-amount">{{remindersAmounts}}</div></div>
      </template>
      <template slot="tab-panel-notification">
        <NotificationList  @notification-update="notificationUpdate" />
      </template>
    </TabsNotification>
  </div>
</template>
<script>
import MessagesList from '../components/MessagesList'
import InvitesList from '../components/InvitesList'
import NotificationList from '../components/NotificationList'
import TabsNotification from '../components/TabsNotification'
export default {
  name: 'MessagesNotification',
  components: {
    MessagesList,
    InvitesList,
    NotificationList,
    TabsNotification
  },
  props: {
    tab: String
  },
  data () {
    return {
      activeTab: this.tab ? this.tab : 'message',
      type: 'notificationMenu',
      tabs: ['message', 'invites', 'notification'],
      chatsAmount: 0,
      remindersAmounts: 0
    }
  },
  created () {
    this.getMessages()
    this.getReminders()
  },
  methods: {
    notificationUpdate () {
      this.getReminders()
    },
    getMessages () {
      this.$https('notifications?for=chats', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.chatsAmount = data
        })
        .catch(error => {
          console.log('', error)
        })
    },
    getReminders () {
      this.$https('notifications?for=reminders', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.remindersAmounts = data
        })
        .catch(error => {
          console.log('', error)
        })
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      if (!window.messageInterval) {
        window.messageInterval = window.setInterval(() => {
          this.getMessages()
          this.getReminders()
        }, 30000)
      }
    })
  },
  destroyed () {
    if (window.messageInterval) {
      window.clearInterval(window.messageInterval)
      window.messageInterval = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box{
    min-height: 100vh;
  }
  .notification-amount {
    font-size: 10px;
    width: 15px;
    height: 15px;
    background-color: #f94c4c;
    border-radius: 50%;
    color: #fff;
    margin-left: 4px;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 320px) {
    .wrapper {
      margin-left: 10px;
    }
  }
</style>
