<template>
  <div>
    <div class="invitation-wrapper" v-if="isLoaded">
      <ul v-if="invited.length > 0" class="notification-list d-flex flex-column fh-container">
        <swipe-list
          ref="list"
          class=""
          :items="invited"
          item-key="id"
          @swipeout:click="close"
        >
          <template v-slot="{ item }">
            <li class="notification profile-notification">
                <div class="slide-option no-border" >
                <div class="slide-option-slide">
                  <div class="slide-option-content">
                <div class="container">
                  <div class="row">
                    <div class="col-3">
                      <a href="" class="notification-avatar">
                        <img :src="require('../assets/avatar-placeholder.jpg')"  :alt="item.name" />
                      </a>
                    </div>
                    <div class="notification-content col-9 pl-0">
                      <div class="notification-content-header row">
                        <div class="col-7 info-title">
                          <a href="#">{{item.name}}</a>
                        </div>
                        <div class="col-5 info-date">{{item.created_date.slice(0, 10)}}</div>
                      </div>
                      <div class="notification-content-content">
                        Wysłałeś zaproszenie do aplikacji na email
                        <span class="notification-strong"
                          >{{item.email}}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
            </li>
          </template>
          <template v-slot:right="{ item }">
            <div @click="remove(item)" class="swipeout-action option-red text-center">
                    <a href="#">
                      <i class="far fa-trash-alt"></i>
                      <span class="d-block mt-1">
                        Usuń
                      </span>
                    </a>
            </div>
          </template>
          <template v-slot:empty>
            <div>
              Brak wiadomości
            </div>
          </template>
        </swipe-list>
        <div class="container mt-auto">
          <button @click="inviteFriends()" class="btn-black-full w-100 mt-3">Wyślij zaproszenie do Bookme</button>
        </div>
      </ul>
      <div v-else>
        <ul class="notification-list">
          <div class="container empty-messages-info text-center">
            <b>Czytaj z przyjaciółmi</b>
          </div>
          <div class="container empty-container pb-4" >
            <div class="quote messages-quote">
              Prawdziwa przyjaźń <br>przychodzi wtedy, gdy milczenie <br>dwóch osób ich nie niepokoi
            </div>
            <div>
              <div class="text-center mb-3">
                Zaproś do Bookme <br>swoich przyjaciół i znajomych
              </div>
              <button @click="inviteFriends()" class="btn-black-full w-100 mt-2">Wyślij zaproszenie do Bookme</button>
            </div>
          </div>
<!--        <li v-for="(i, key) in left" :key="key" class="notification profile-notification" @click="inviteFriends()">-->
<!--            <div class="slide-option no-border" >-->
<!--            <div class="slide-option-slide">-->
<!--              <div class="slide-option-content">-->
<!--            <div class="container">-->
<!--              <div class="row">-->
<!--                <div class="col-3">-->
<!--                  <a href="" class="notification-avatar">-->
<!--                    <img :src="require('../assets/avatar-placeholder.jpg')" alt="zaproś znajomego" />-->
<!--                  </a>-->
<!--                </div>-->
<!--                <div class="notification-content col-9 pl-0">-->
<!--                  <div class="notification-content-header row">-->
<!--                    <div class="col-7 info-title">-->
<!--                      <a href="#">Zaproś znajomego</a>-->
<!--                    </div>-->
<!--                    <div class="col-5 info-date"></div>-->
<!--                  </div>-->
<!--                  <div class="notification-content-content">-->
<!--                    Wyślij zaproszenie do aplikacji wpisując e-mail-->
<!--                    <span class="notification-strong"-->
<!--                      ></span-->
<!--                    >-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
      </ul>
      </div>
      <!-- <div  v-else class="container wrapper-empty">
        <div class="book-heading-big text-center mt-4">Brak zaproszeń</div>
      </div> -->
      <ModalSlot title="Wyślij zaproszenie" v-if="invitePopup" @close-modal="closeModal">
        <div>
          <h2 class="text-center mb-4">Wyślij zaproszenie</h2>
          <ValidationObserver ref="observer" tag="form" >
            <form @sumbit.prevent>
              <div class="row align-items-center">
                <div class="col-12">
                  <div class="input-container mb-3 input-wrapper">
                    <ValidationProvider rules="required" v-slot="{errors}">
                        <input type="text" class="form-input w-100"
                          placeholder="Imię" v-model="inviteName"/>
                        <span class="form-error-info text-center">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-12">
                  <div class="input-container mb-3 input-wrapper">
                    <ValidationProvider :rules="{required: true, email: true}" v-slot="{errors}">
                        <input type="email" class="form-input w-100"
                          placeholder="adres e-mail" v-model="inviteEmail"/>
                        <span class="form-error-info text-center">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-12">
                  <div class="input-container mb-0 input-wrapper">
                    <ValidationProvider rules="required" v-slot="{errors}" >
                      <textarea type="text" class="form-input w-100 control"
                        name="" placeholder="Cześć..." v-model="inviteMessage"
                      ></textarea>
                      <span class="form-error-info text-center">{{ errors[0] }}</span>
                      <button type="submit" @click.prevent="submit"  class="btn-black-full w-100 mt-3">Wyślij</button>
                      <button type="button" class="btn-gray-border w-100 mt-3 mb-2" @click="closeModal">Anuluj</button>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </ModalSlot>
      <ModalSlot title="Zaproszenie wysłanne" v-if="isPopupShown" @close-modal="closePopup(false)">
        <div class="text-center mb-2 modal-main-title">{{popupMessage}}</div>
        <button class="btn-black-full w-100 mt-1 mb-1" @click="closePopup(false)">Zamknij</button>
      </ModalSlot>
    </div>
    <div v-else><Loader /></div>
  </div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions'
// import ModalInviteFriends from './ModalInviteFriends'
import Loader from './Loader'
import ModalSlot from '../components/ModalSlot'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

extend('email', {
  ...email,
  message: 'Podaj poprawny adres email'
})

export default {
  name: 'InvitesList',
  components: {
    SwipeList,
    ValidationProvider,
    ValidationObserver,
    Loader,
    ModalSlot
  },
  data () {
    return {
      isModalOpen: false,
      left: 0,
      invited: [],
      isLoaded: false,
      isPopupShown: false,
      invitePopup: false,
      popupMessage: 'Zaproszenie zostało wysłane',
      inviteName: '',
      inviteEmail: '',
      inviteMessage: ''
    }
  },
  computed: {
    my_id () { return this.$store.state.my_id }
  },
  async created () {
    await this.getInvitation()
  },
  methods: {
    closePopup () {
      this.isPopupShown = false
      this.popupMessage = ''
      this.isLoaded = false
      this.getInvitation()
    },
    getInvitation () {
      this.$https('/invitations', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(res => {
          this.left = res.data.response.left
          this.invited = res.data.response.invitations
          this.isLoaded = true
        })
        .catch(error => {
          console.log('', error)
        })
    },
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        this.inviteFriend()
      }
    },
    inviteFriend () {
      const getData = `email=${this.inviteEmail}&name=${this.inviteName}&content=${this.inviteMessage}`
      this.$https('/invitations', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
        if (response.data.status === 'error') {
          this.popupMessage = 'Zaproszenie na podany adres e-mail zostało juz wysłane'
          this.isPopupShown = true
        }
        if (response.data.status === 'success') {
          this.popupMessage = 'Zaproszenie zostało wysłane pomyślnie'
          this.isPopupShown = true
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    remove (item) {
      this.invited = this.invited.filter(i => i !== item)
    },
    close (e) {
      this.$refs.list.closeActions(e.id)
    },
    inviteFriends (e) {
      this.invitePopup = true
    },
    closeModal () {
      this.invitePopup = false
      this.inviteEmail = ''
      this.inviteName = ''
      this.inviteMessage = ''
    }
  }
}
</script>
<style lang="scss" scoped>
    .invitation-wrapper {
    margin-top: 40px;
  }
  .empty-messages-info{
    padding-top: 30px;
  }
  .fh-container{
    min-height: calc(100vh - 156px);
    padding-bottom: 50px;
  }
</style>
