<template>
  <div>
    <div class="notification-list-wrapper" v-if="!isLoading">
      <ul class="notification-list" v-if="notifications.length > 0">
        <swipe-list
          ref="list"
          class=""
          :items="notifications"
          item-key="id"
          @swipeout:click="close"
        >
          <template v-slot="{ item, index }">
            <div v-if="item.type === 'recomendbook' || item.type === 'likebook' || (item.type === 'systeminfo' && item.book_id)">
              <router-link :to="{name: 'BooksDetails', params: {id: item.book_id}}">
                <li class="notification profile-notification">
                  <div class="slide-option no-border">
                    <div class="slide-option-slide">
                      <div class="slide-option-content">
                        <div class="container">
                          <div class="row">
                            <div class="col-3">
                              <a href="" class="notification-avatar">
                                <img :src="item.thumb_url ? plainUrl + item.thumb_url :  require('../assets/avatar-placeholder.jpg')" :alt="item.created_date" />
                              </a>
                            </div>
                            <div class="notification-content col-9 pl-0">
                              <div class="notification-content-header row">
                                <div class="col-7 info-title">
                                  <a href="#">{{item.username}}</a>
                                </div>
                                <div class="col-5 info-date">{{item.created_date}}</div>
                              </div>
                              <div class="notification-content-content">
                                {{item.content}}
                              </div>
                                <!-- <span class="notification-strong"
                                  >{{item.username}}<br />{{}}</span
                                > -->
                              <!-- <div class="notification-content-footer row" v-if="item.isNotConfirm">
                                <div class="col-6 pr-1">
                                  <button class="btn-black-border btn-medium w-100" @click="item.isNotConfirm = false">
                                    Potwierdzam
                                  </button>
                                </div>
                                <div class="col-6 pl-1">
                                  <button class="btn-black-border btn-medium w-100" @click="item.isNotConfirm = false">
                                    Nie
                                  </button>
                                </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side-option-list">
                        <a href="#" class="option-red">
                          <span>
                            <i class="fas fa-trash-alt"></i>
                            Usuń
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </router-link>
            </div>
            <div v-else-if="item.type === 'likereview'">
              <router-link :to="{name: 'ReviewDetails', params: {id: item.review_id, book_id: item.book_id}}">
                <li class="notification profile-notification">
                  <div class="slide-option no-border">
                    <div class="slide-option-slide">
                      <div class="slide-option-content">
                        <div class="container">
                          <div class="row">
                            <div class="col-3">
                              <a href="" class="notification-avatar">
                                <img :src="item.thumb_url ? plainUrl + item.thumb_url :  require('../assets/avatar-placeholder.jpg')" :alt="item.created_date" />
                              </a>
                            </div>
                            <div class="notification-content col-9 pl-0">
                              <div class="notification-content-header row">
                                <div class="col-7 info-title">
                                  <a href="#">{{item.username}}</a>
                                </div>
                                <div class="col-5 info-date">{{item.created_date}}</div>
                              </div>
                              <div class="notification-content-content">
                                {{item.content}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side-option-list">
                        <a href="#" class="option-red">
                          <span>
                            <i class="fas fa-trash-alt"></i>
                            Usuń
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </router-link>
            </div>
            <div v-else>
              <router-link v-if="item.u_id" :to="item.u_id ? {name: 'OtherUserProfile', params: {id: item.u_id}} : {}">
                <li class="notification profile-notification">
                  <div class="slide-option no-border">
                    <div class="slide-option-slide">
                      <div class="slide-option-content">
                        <div class="container">
                          <div class="row">
                            <div class="col-3">
                              <a href="" class="notification-avatar">
                                <img :src="item.thumb_url ? plainUrl + item.thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="item.created_date" />
                              </a>
                            </div>
                            <div class="notification-content col-9 pl-0">
                              <div class="notification-content-header row">
                                <div class="col-7 info-title">
                                  <a href="#">{{item.username}}</a>
                                </div>
                                <div class="col-5 info-date">{{item.created_date}}</div>
                              </div>
                              <div class="notification-content-content">
                               {{newContent[index]}}
                              </div>
                                <!-- <span class="notification-strong"
                                  >{{item.username}}<br />{{}}</span
                                > -->
                              <div class="notification-content-footer row" v-if="item.type === 'giveback'">
                                <div class="col-6 pr-1">
                                  <button class="btn-black-border btn-medium w-100" @click.prevent="bookGiveback(item, item.book_id, index)">
                                    Potwierdzam
                                  </button>
                                </div>
                                <div class="col-6 pl-1">
                                  <button class="btn-black-border btn-medium w-100" @click.prevent="bookNotGiveback(item, item.book_id, index)">
                                    Nie
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side-option-list">
                        <a href="#" class="option-red">
                          <span>
                            <i class="fas fa-trash-alt"></i>
                            Usuń
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </router-link>
            </div>
          </template>
          <template v-slot:right="{ item }">
            <div @click="remove(item)" class="swipeout-action option-red text-center">
                    <a href="#">
                      <i class="far fa-trash-alt"></i>
                      <span class="d-block mt-1">
                        Usuń
                      </span>
                    </a>
            </div>
          </template>
          <template v-slot:empty>
            <div>
              Brak wiadomości
            </div>
          </template>
        </swipe-list>
      </ul>
      <div  v-else class="notification-list">
        <div class="container empty-messages-info text-center">
          <b>Uff, nic tu nie ma</b>
        </div>
        <div class="container pb-4" >
          <div class="quote messages-quote">
            Czas, im bardziej jest <br>pusty, tym szybciej płynie
          </div>
        </div>
      </div>
    </div>
    <div v-else><Loader /></div>
  </div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions'
import Loader from '../components/Loader'
import EventBus from '../eventBus'
export default {
  name: 'NotificationList',
  components: {
    SwipeList,
    Loader
  },
  data () {
    return {
      notifications: [],
      newContent: [],
      isLoading: true,
      isNotConfirm: []
    }
  },
  async created () {
    await this.$https('/reminders', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(res => {
        this.notifications = res.data.response
        // const test = this.notifications.map(function (x) {
        //   return x.content.replace('<br>', '\n')
        // })
        this.notifications.forEach(e => {
          this.newContent.push(e.content.split('<br>').join('\n'))
        })
        this.notifications.forEach(e => {
          if (e.type === 'giveback') {
            this.isNotConfirm.push(true)
          } else {
            this.isNotConfirm.push(false)
          }
        })
        this.isLoading = false
        this.$emit('notification-update')
        EventBus.$emit('reload-notification', true)
        this.newNotification = this.newNotification + 1
      })
      .catch(error => {
        console.log('', error)
      })
  },
  computed: {
    newNotification: {
      get () {
        return this.$store.state.newNotification
      },
      set (value) {
        this.$store.commit('updateNewNotification', value)
      }
    }
  },
  methods: {
    bookGiveback (item, id, key) {
      this.$https('book/' + id + '/giveback', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.isNotConfirm[key] = false
        this.remove(item)
      })
        .catch(error => {
          console.log('', error)
        })
    },
    bookNotGiveback (item, id, key) {
      this.$https('book/' + id + '/notgiveback', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.isNotConfirm[key] = false
        this.remove(item)
      })
        .catch(error => {
          console.log('', error)
        })
    },
    remove (item) {
      this.notifications = this.notifications.filter(i => i !== item)
      const getData = `action=delete&my_id=${item.user_id}`
      this.$https('/reminders/' + item.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
      })
        .catch(error => {
          console.log('', error)
        })
    },
    close (e) {
      this.$refs.list.closeActions(e.id)
    }
  }
}

</script>
<style lang="scss" scoped>
  .notification-list-wrapper {
    margin-top: 40px;
    padding-bottom: 30px;
    &-empty {
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .empty-messages-info{
    padding-top: 30px;
  }
</style>
